import React, { useState, useEffect } from "react";
import "./ModalValueOffer.css";

function DropDownMenu({ listValues, isModalVisible, onDeleteClick, selectedValues, setSelectedValues }) {
  //const [selectedValues, setSelectedValues] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCheckboxChange = (id) => {
    setSelectedValues((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((value) => value !== id)
        : [...prevSelected, id]
    );
  };

  const handleCollapseToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <>
      {isModalVisible && (
        <div className="modal-container">
          {!isCollapsed && (
            <>
              <label>OFERTAS DE VALOR*</label>
              <div className="checkbox-list">
                {listValues.map((item) => (
                  <label key={item.id}>
                    <input
                      type="checkbox"
                      checked={selectedValues.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                    {item.description}
                  </label>
                ))}
              </div>
          </>
          )}
          <button className="checkbox-list-button mb-3" 
            onClick={handleCollapseToggle}
            icon="arrow">
            {isCollapsed ? "+ Mostrar ofertas de valor" : "- Ocultar ofertas de valor"}
          </button>
          <div className="button-container">
            <button className="buttonDelete"
              onClick={(e) => onDeleteClick(e.target.isModalVisible)}>
              Eliminar
            </button>
          </div>
          <div className="mt-2"/>
          <hr/>
        </div>
      )}
    </>
  );
}

function ModalValueOffer({isModalVisible, onDeleteClick, values, selectedValues, setSelectedValues}) {

  return (
    <DropDownMenu
      listValues={values}
      isModalVisible={isModalVisible}
      onDeleteClick={onDeleteClick}
      selectedValues={selectedValues}
      setSelectedValues={setSelectedValues}
  />);
}

export { ModalValueOffer };