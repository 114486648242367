import React from "react";
import { View, Image, Link } from "@react-pdf/renderer";

// Define el arreglo de iconos con sus enlaces
/*const icons = [
  {
    src: "https://img.icons8.com/?size=100&id=Pz213it3TQxp&format=png&color=000000", // Icono 1
    url: "https://google.com", // Enlace 1
  },
  {
    src: "https://img.icons8.com/?size=100&id=KicY7hU3yufx&format=png&color=000000", // Icono 2
    url: "https://example.com", // Enlace 2
  },
  {
    src: "https://img.icons8.com/?size=100&id=XlVvYlR6GkuW&format=png&color=000000", // Icono 3
    url: "https://another-example.com", // Enlace 3
  },
];*/

function SocialNetworksLinks({icons}) {
  return (
    <View
      style={{
        display: "flex",
        //backgroundColor: 'lightgray',
        justifyContent: "center",
        alignItems: "center",
        height: "50",
        width: "100%",
        position: "relative",
        top: 0,
        left: 0,
        zIndex: 1, // Asegura que el ícono esté delante del fondo
        flexDirection: "row", // Disposición horizontal de los iconos
        //justifyContent: "space-around", // Espacio igual entre los iconos
        paddingTop: 0, // Ajusta la posición vertical
      }}
    >
      {icons.map((icon, index) => (
        <Link
          key={index}
          src={icon.url}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 8, // Espacio entre iconos
          }}
        >
          <Image
            src={icon.icon}
            style={{
              width: 20, // Ajusta el tamaño del ícono según sea necesario
              height: 20,
            }}
          />
        </Link>
      ))}
    </View>
  );
}

export default SocialNetworksLinks;
