import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClient } from '../services/Client.service';
import messageBox from '../utils/messagebox';
import Loading from '../components/Loading/Loading';
import LogoSearch from '../images/Home/Vector.png';
import ClientComponent from './ClientComponent';

function Home() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [clientsSearch, setClientsSearch] = useState([]);
  const [clients, setClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const { data } = await getClient();
        if (data.error) {
          messageBox.Simple(data.mensaje, 'error');
        } else {
          setClients(data.sort((a, b) => a.id - b.id));
        }
      } catch (err) {
        messageBox.Simple(err.message, 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const handleClientDetails = (id) => {
    navigate("../clientDetails", { state: { id } });
  };

  const handleSearch = (value) => {
    const lowercasedValue = value.trim().toLowerCase();
    const filteredClients = clients.filter(client => {
      const fullName = `${client.firstName || ''} ${client.secundName || ''} ${client.firstLastName || ''} ${client.SecundLastName || ''}`.toLowerCase();
      return isNaN(value) ? fullName.includes(lowercasedValue) : client.id === Number(value);
    });
    setSearch(value);
    setClientsSearch(filteredClients);
  };

  const numClientsPerPage = 10;

  const totalClients = search.length > 0 ? clientsSearch.length : clients.length;
  const totalPages = Math.ceil(totalClients / numClientsPerPage);
  const currentPageNumber = Math.floor(currentPage / numClientsPerPage) + 1;

  // Determine which page buttons to show
  const pageButtons = [];
  const startPage = Math.max(1, currentPageNumber - 1);
  const endPage = Math.min(totalPages, currentPageNumber + 1);

  for (let i = startPage; i <= endPage; i++) {
    pageButtons.push(i);
  }

  const renderPagination = () => (
    <div className="flex gap-2 mt-3 mb-2 mr-1 justify-end">
      <button
        className='paginationLeftAndRightButton p-1.5 flex items-center'
        onClick={() => currentPage > 0 && setCurrentPage(currentPage - numClientsPerPage)}
        disabled={currentPage === 0}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="12.5" height="12.5" viewBox="0 0 6 10" fill="none">
          <path d="M5.18826 9.79377L0.33312 5.6808C-0.11104 5.29064 -0.11104 4.68914 0.33312 4.31523L5.18826 0.202269C5.64773 -0.187894 6 0.00718769 6 0.608689L6 9.38735C6 10.0051 5.64773 10.1839 5.18826 9.79377Z" fill="currentColor" />
        </svg>
      </button>
      {pageButtons.map(page => (
        <button
          key={page}
          className={`paginationButtons px-2 py-0.5 flex items-center ${currentPageNumber === page ? 'activePagination' : ''}`}
          onClick={() => setCurrentPage((page - 1) * numClientsPerPage)}
        >
          {page}
        </button>
      ))}
      <button
        className='paginationLeftAndRightButton p-1.5 flex items-center'
        onClick={() => currentPage + numClientsPerPage < totalClients && setCurrentPage(currentPage + numClientsPerPage)}
        disabled={currentPage + numClientsPerPage >= totalClients}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="12.5" height="12.5" viewBox="0 0 6 11" fill="none">
          <path d="M0.811742 0.226854L5.66688 4.75112C6.11104 5.1803 6.11104 5.84195 5.66688 6.25324L0.811742 10.7775C0.352265 11.2067 0 10.9921 0 10.3304L0 0.673916C0 -0.00561714 0.352265 -0.202325 0.811742 0.226854Z" fill="currentColor" />
        </svg>
      </button>
    </div>
  );

  if (loading) return <Loading />;

  const displayedClients = search.length > 0 ? clientsSearch : clients.slice(currentPage, currentPage + numClientsPerPage);

  return (
    <>
      <div className="py-6">
        <h1 className="2xl:text-6xl lg:text-5xl text-4xl" style={{ color: "#004750", fontWeight: "700" }}>
          Clientes
        </h1>
      </div>
      <div className="lg:flex block justify-between">
        <div className="lg:flex block">
          <div className="mb-3" style={{ position: 'relative' }}>
            <input
              id="defaultInput"
              className="form-control rounded-pill lg:w-96 lg:14"
              style={{ backgroundColor: "#F1F1F1", color: "#B9B9B9", borderBlockColor: "#B9B9B9", paddingLeft: '40px' }}
              value={search}
              onChange={e => handleSearch(e.target.value)}
              type="text"
              placeholder="Ingresa el Nombre o el ID de tu cliente"
            />
            <img src={LogoSearch} alt='Lupa de búsqueda' style={{ position: 'absolute', top: '50%', left: '15px', transform: 'translateY(-50%)' }} />
          </div>
        </div>
      </div>
      {search && clientsSearch.length === 0 && (
        <h4 className='text-lg font-semibold'>No se encontró ningún cliente que coincida con la búsqueda.</h4>
      )}
      <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4 my-2">
  {displayedClients.map((client) => (
    <div className="col lg:mt-0 md:mx-2 mx-1 mt-3" key={client.id}>
      <div className="card h-80 w-full flex flex-col justify-between lg:mx-1 center-custom py-3" style={{ backgroundColor: "rgba(148, 160, 160, 0.2)" }}>
        <ClientComponent clienteId={client.id} />
        <div className="card-body flex flex-col flex-grow">
          <div>
          <h5 className="card-title my-1 font-semibold">
                  {client.firstName || client.secundName || client.firstLastName || client.SecundLastName || 'Sin nombre registrado'}
                </h5>
            <p className="card-text my-1">{client.company || 'No data (Empresa)'}</p>
          </div>
          <div className="flex flex-col gap-2 mt-2">
            <button
              type="button"
              className="btn rounded-pill ColorButtonMain lg:text-sm xl:text-md"
              onClick={() => handleClientDetails(client.id)}
            >
              Detalles del perfil
            </button>
            <button
              type="button"
              className="btn rounded-pill ColorButtonMainInv lg:text-sm xl:text-md"
              onClick={() => handleClientDetails(client.id)}
            >
              Crear proyecto
            </button>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>

      {(search.length === 0 && clientsSearch.length === 0) && clients.length > 0 && renderPagination()}
    </>
  );
}

export default Home;
