import React, { useRef, useState, useEffect } from 'react';
import Avatar1 from "../../../images/avatars/1.png";
//import axios from 'axios';
import { obtenerImagenPorId, updateFoto} from '../../../services/Client.service';
import LogoPencil from '../../../images/camera-add.svg';

function AvatarUpload({ clienteId }) {
  const [imageSrc, setImageSrc] = useState(Avatar1); // Estado para la imagen actual
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageBlob = await obtenerImagenPorId(clienteId);
        const imageObjectURL = URL.createObjectURL(imageBlob);
        //console.log(imageObjectURL);
        setImageSrc(imageObjectURL);
      } catch (error) {
        console.error('Error al obtener la imagen:', error);
      }
    };

    fetchImage();
  }, [clienteId]);

  const handleFileUpload = () => {
    const file = fileInputRef.current?.files[0];
    if (file) {
      // Lee el archivo y actualiza el estado de la imagen
      const reader = new FileReader();
      reader.onloadend = async () => {
        setImageSrc(reader.result);

        // Crear un FormData
        const formData = new FormData();
        formData.append('imagen', file);
        formData.append('id', clienteId); // 'imagen' es el nombre del campo que espera el backend

        try {
          await updateFoto(formData);
        } catch (error) {
          console.error('Error al subir la imagen:', error);
        }
      };
      reader.readAsDataURL(file);
    } else {
      console.log('No file selected');
    }
  };

  return (
    <div className="flex flex-col items-center mt-8">
      <img
        src={imageSrc}
        alt="User Avatar"
        className="w-40 h-40 rounded-full m-auto lg:ml-8"
      />
      <input
        type="file"
        id="imageFile"
        accept="image/*"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileUpload} // Usa el evento onChange para manejar el archivo seleccionado
      />
            <label htmlFor="imageFile">
        <img 
          src={LogoPencil}
          alt='Cargar imagen'
          className="w-4 h-4 rounded-circle"
                    style={{
                      top: '-180%',
                      left: '250%',
                      position: 'relative',
                      width: '25px',
                      height: '25px',
                      transform: 'translateY(-50%)'
                    }}/>
      </label>
    </div>
  );
}

export default AvatarUpload;
