import React from 'react';
import IconEdit from "../../images/Cotizaciones/Vector.png";
import IconDelete from "../../images/Cotizaciones/mdi_delete-outline.png";
import { formatDate } from "../../utils/tools";

const QuoteTableBody = ({ quotes, search, quotesSearch, stylesStatus, handleShowPopup, editQuote, deleteQuote, redirectQuoteHistory }) => {
  
    // Verifica que quotes, search y quotesSearch estén llegando correctamente
    //console.log('ProjectNAme:', editQuote);
  
    return (
    <tbody className="divide-y divide-gray-200">
      {quotes
        .filter(project => search === "" || quotesSearch.some(q => q.id === project.id))
        .map(project => {
          const { id, status, projectName, reference, services, price, createDate } = project;
          //console.log(createDate);
          return (
            <tr key={id}>
              <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">{id}</td>
              <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 text-center">
                <div className={`text-sm font-medium ${stylesStatus[status]?.color}`}>
                  {stylesStatus[status]?.text}
                </div>
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 text-center">
                <div className="flex justify-center">
                  <img
                    src={IconEdit}
                    className="hover:cursor-pointer"
                    onClick={() => {
                      //handleShowPopup();
                      editQuote(project, true);
                      
                    }}
                    alt="Edit Icon"
                    style={{ width: "20px" }}
                  />
                  
                  <img
                    src={IconDelete}
                    className="hover:cursor-pointer mx-3"
                    onClick={() => deleteQuote(id)}
                    alt="Delete Icon"
                    style={{ width: "20px" }}
                  />
                </div>
              </td>
              {/*<td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 text-center">
                <button
                  onClick={() => redirectQuoteHistory(project)}
                  className="text-blue-600 underline hover:cursor-pointer"
                >
                  {projectName} 
                </button>
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 text-center">
                {reference}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 text-center">
                {services}
              </td>*/}
              <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 text-center">
                ${price}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 text-center">
                {formatDate(createDate)}
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default QuoteTableBody;
