import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../Quotes/ModalEditQuote";
import messageBox from "../../../utils/messagebox";
import Loading from "../../../components/Loading/Loading";
import { getClient, updateFoto } from "../../../services/Client.service";
import {
  getProjectId,
  addProjectId,
  getProjectClientId,
  deleteProjectId,
} from "../../../services/Projects.service";
import { getQuotesFromProjectsId } from "../../../services/Quotes.service";
import { UserContext } from "../../../context/UserContext";
import CreateProjects from "./CreateProjects";
import CreateQuote from "../../Quotes/CreateQuote";

import LogoView from "../../../images/Frame 108.png";
//import LogoDownload from "../../../images/mdi_file-download-outline.png";
import IconAdd from "../../../images/carbon_add.svg";
import IconEdit from "../../../images/Cotizaciones/Vector.png";
import IconDelete from "../../../images/Cotizaciones/mdi_delete-outline.png";
import AvatarUpload from "./AvatarUpload";

import { formatDate } from "../../../utils/tools";
//import { PDFDownloadLink } from "@react-pdf/renderer";
//import PDF from "../../Quotes/GeneratePdf/pdf";
import {
  getProducts,
  getTermsAndConditions,
} from "../../../services/Admin.service";
import { getUser } from "../../../services/Users.service";

export default function ClientsDetails() {
  let navigate = useNavigate();
  let locations = useLocation();
  let idClient = locations.state.id;
  const userContext = useContext(UserContext);
  let Context = userContext.user;

  let idQuotes = 0;

  let [isLoading, setLoading] = useState(true);
  //let [imagenCliente, setImagenCliente] = useState('../../../images/avatars/1.png');
  let [Quotes, setQuotes] = useState();
  let [isUpdate, setIsUpdate] = useState(false);
  let [products, setProducts] = useState([]);
  let [activeModal, setActiveModal] = useState(false);
  const [dataClient, setDataClient] = useState({});
  const [dataProjects, setDataProjects] = useState([]);
  const [dataProject, setDataProject] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalCot, setShowModalCot] = useState(false);
  const [clientId, setClientId] = useState();
  const [contactId, setContactId] = useState();
  const [projectId, setProjectId] = useState();
  const [reference, setReference] = useState();
  const [review, setReview] = useState();
  const [title, setTitle] = useState();
  const [m2, setM2] = useState();
  const [location, setLocation] = useState();
  const [description, setDescription] = useState();
  const [scope, setScope] = useState();
  let [savesQuotes, setSavesQuotes] = useState({});
  let [conceptsProps, setConceptsProps] = useState();
  let [idQuoteLocalStorage, setIdQuoteLocalStorage] = useState();
  let [dataOwner, setDataOwner] = useState([]);
  let [termsAndConditions, setTermsAndConditions] = useState({});

  const fileInputRef = useRef();


  useEffect(() => {
    _getClientDetails();
  }, []);

  async function _getClientDetails() {
    try {
      //console.log('Iniciando carga de detalles del cliente...');
      
      // Obtener detalles del cliente
      const { data: clientData, status: clientStatus } = await getClient(idClient);
      //console.log('Status de la solicitud cliente:', clientStatus);
      //console.log('Datos del cliente:', clientData);
      if (clientData.error) {
        messageBox.Simple(clientData.message, "error");
        return;
      }
      
      // Obtener foto del cliente
      /*const { data: photoData, status: photoStatus } = await obtenerFoto('164636.jpg');
      console.log('Status de la solicitud foto:', photoStatus);
      console.log('Datos de la foto:', photoData);
      if (photoData.error) {
        console.log('Error en la foto:', photoData.message);
        return;
      }
      
      setImagenCliente(photoData.base64Image);*/
      
      // Configurar datos del cliente
      setDataClient(clientData);
      setClientId(clientData.id);
      setContactId(clientData.id);
      
      // Obtener proyectos del cliente
      const { data: projectData, status: projectStatus } = await getProjectClientId(idClient);
      //console.log('Status de la solicitud proyectos:', projectStatus);
      //console.log('Datos del proyecto:', projectData);
      if (projectData.error) {
        messageBox.Simple(projectData.message, "error");
        return;
      }
      
      setDataProjects([...projectData]);
      
      // Obtener cotizaciones de los proyectos
      const { data: quotesData, status: quotesStatus } = await getQuotesFromProjectsId(projectData);
      //console.log('Status de la solicitud cotizaciones:', quotesStatus);
      //console.log('Datos de cotizaciones:', quotesData);
      if (quotesData.error) {
        messageBox.Simple(quotesData.message, "error");
        return;
      }
      
      setQuotes(quotesData);
      
      // Obtener productos
      const { data: productsData, status: productsStatus } = await getProducts();
      //console.log('Status de la solicitud productos:', productsStatus);
      //console.log('Datos de productos:', productsData);
      if (productsData.error) {
        messageBox.Simple(productsData.mensaje, "error");
        return;
      }
      
      setProducts(productsData);
      setSavesQuotes(JSON.parse(localStorage.getItem("quoteSave")) || {});
      
      // Obtener términos y condiciones
      const { data: termsAndConditionsData, status: termsStatus } = await getTermsAndConditions();
      //console.log('Status de la solicitud términos y condiciones:', termsStatus);
      //console.log('Datos de términos y condiciones:', termsAndConditionsData);
      setTermsAndConditions(termsAndConditionsData);
      
      // Configurar propietarios
      projectData.forEach((project) => {
        if (quotesData[project.id]) {
          quotesData[project.id].forEach((quote) => {
            sessionStorage.setItem("hubspotOwnerId", quote.hubspotOwnerId);
            getDataOwners(project.id, quote.hubspotOwnerId);
          });
        }
      });
      
      setLoading(false);
    } catch (error) {
      console.error('Error en _getClientDetails:', error);
      messageBox.Simple('Error en la carga de detalles del cliente', "error");
    }
  }
  

  async function getDataOwners(idProject, idOwner) {
    await getUser(idOwner)
      .then(({ data }) => {
        let tempArray = [...dataOwner];
        tempArray[idProject] = data;
        //console.log(tempArray);
        setDataOwner([...tempArray]);
      })
      .catch((err) => messageBox.Simple(err, "error"));
  }

  const _getProjectId = async (idP) => {
    await getProjectId(idP).then(({ data }) => {
      if (data.error) messageBox.Simple(data.message, "Error");
      else {
        setDataProject(data);
        setProjectId(data.id);
        setClientId(data.clientId);
        setContactId(data.contactId);
        setReference(data.reference);
        setReview(data.review);
        setTitle(data.title);
        setM2(data.m2);
        setLocation(data.location);
        setDescription(data.description);
        setScope(data.scope);
      }
    });
  };

  const _redirectQuotesClient = async (idClient) => {
    navigate("../clientQuotes", { state: { idClient } });
  };

  const _addClientProject = async (e) => {
    e.preventDefault();
    const obj = {
      clientId,
      contactId,
      reference,
      review,
      title,
      m2,
      location,
      description,
      scope,
    };
    await addProjectId(obj).then(({ data }) => {
      if (data.error) messageBox.Simple(data.message, "Error");
      else {
        messageBox.Simple(data.message, "success");
        setTimeout(() => window.location.reload(), 2000);
      }
    });
  };

  function editProject(projectId) {
    _getProjectId(projectId);
    setIsUpdate(true);
    setShowModalCot(true);
  }

  async function deleteProject(projectId) {
    await deleteProjectId(projectId)
      .then(({ data }) => {
        if (data.error) {
          messageBox.Simple(data.message, "error");
        } else {
          messageBox.Simple(data.message, "success");
          setTimeout(() => window.location.reload(), 2000);
        }
      })
      .catch((err) => messageBox.Simple(err, "error"));
  }

  async function previewQuote(project, isUpdate) {
    await getProjectId(project.projectId).then(async ({ data }) => {
      
      if (data.error) return messageBox.Simple(data.message, "error");
      data = { ...data, ...project };
      setDataProject(data);
      await getClient(project.clientId)
        .then(({ data }) => {
          if (data.error) return messageBox.Simple(data.message, "error");
          setDataClient(data);
          setIsUpdate(false);
          setActiveModal(true);
        })
        .catch((err) => messageBox.Simple(err, "error"));
    });
  }

  const handleFileUpload = async(event) => {
    const file = fileInputRef.current.files[0];
    const formData = new FormData();
    formData.append('imagen', file);
    formData.append('id', idClient);
    //console.log('imagen');
    await updateFoto(formData);
  };

  async function changeFoto(files, type) {
    if (!files.length === 0) {
      return alert("Debes escoger una imagen.");
    }
    let picture = new FormData();
    picture.append("picture", files[0]);
    picture.append("type", type);
    //console.log('Cambio de foto');
    await updateFoto(picture)
      .then((data) => {
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch((err) => console.error(err));
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div
            style={
              showModal
                ? { pointerEvents: "none", overflow: "hidden", opacity: "0.5" }
                : {}
            }
          >
              <div className="container-p-y flex gap-3">
                <div
                  onClick={() => navigate("../")}
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 50,
                    background: "#00A297",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#FFF"
                    className="bi bi-chevron-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                </div>
                <h4
                  style={{
                    color: "#004750",
                    fontSize: "32px",
                    fontWeight: "700",
                    lineHeight: "1",
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("../")}
                  >
                    Cliente
                  </span>{" "}
                  {">"} {dataClient.firstName ? dataClient.firstName : "No Data"}
                </h4>
              </div>

              <div className="mt-2.5">
                <div>
                  <div
                    className="p-3"
                    style={{
                      backgroundColor: "rgba(148, 160, 160, 0.2)",
                      borderRadius: "25px",
                    }}
                  >

                    {/* Sección de Avatar y Datos del Cliente */}
                    <div className="flex lg:pl-9 items-center">
                      {/* Componente de Avatar */}
                      <AvatarUpload clienteId={clientId} />
                      
                      {/* Información del Cliente */}
                      <div className="lg:ml-10 grid grid-cols-3">
                        {/* Sección de Nombres y Apellidos */}
                        
                          {[
                            { label: 'Nombres', value: dataClient.firstName },
                            { label: 'Apellidos', value: dataClient.firstLastName },
                          ].map(({ label, value }, index) => (
                            <div key={index} className="mt-2 lg:mt-0">
                              <p className="card-text xl:text-base lg:text-xs text-gray-600">{label}</p>
                              <h5 className="card-title fw-semibold">{value || 'No Data'}</h5>
                            </div>
                          ))}
                     

                        {/* Sección de Email, Empresa y Web */}
                          
                            {[
                              { label: 'Email personal', value: dataClient.email },
                              { label: 'Empresa (Marca)', value: dataClient.company },
                              {
                                label: 'Web de la empresa',
                                value: dataClient.website ? (
                                  <a href={`https://${dataClient.website}`} className="text-blue-500">{dataClient.website}</a>
                                ) : 'No Data'
                              },
                            ].map(({ label, value }, index) => (
                              <div key={index} className="mt-2 lg:mt-0">
                                <p className="card-text xl:text-base lg:text-xs text-gray-600">{label}</p>
                                <h5 className="card-title fw-semibold">{value}</h5>
                              </div>
                        ))}
                         
                    </div>

                  
                </div>
                  <button
                    type="button"
                    onClick={() => setShowModal(true)}
                    className="flex btn rounded-pill ColorButtonMain my-4 lg:ml-5 mx-10"
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <img src={IconAdd} alt="" />
                    Crear nuevo proyecto
                  </button>
                </div>
                <div className="mt-4">
                  <img
                      src={LogoView}
                      alt=""
                      srcSet=""
                      style={{ width: "50px", height: "auto" }}
                  />
                  <p
                    className="xl:text-base lg:text-xs text-link fw-semibold"
                    style={{
                      color: "#00a297",
                      width: "100%",
                      fontSize: "1em",
                    }}
                    onClick={() => _redirectQuotesClient(dataClient.id)}
                  >
                    Ver todas las cotizaciones
                  </p>
                </div>

                {dataProjects.length > 0 && (
                  <div>
                    <h4
                      className="lg:mt-12 mt-10 lg:ml-0 ml-20"
                      style={{
                        color: "#004750",
                        fontSize: "32px",
                        fontWeight: "700",
                      }}
                    >
                      Proyectos
                    </h4>
                    <div className="lg:mt-10 grid lg:grid-cols-4 grid-cols-1">
                      {dataProjects.map((data, index) => (
                        <div className="lg:mt-4 mt-10 mx-2" key={index}>
                          <div
                            className="card h-100 py-3 m-0 lg:m-4"
                            style={{
                              backgroundColor: "rgba(148, 160, 160, 0.2)",
                            }}
                          >
                            <div className="card-body">
                              <h5 className="card-title text-center text-2xl mb-2">
                                {data.title ? data.title : "No título"}
                              </h5>
                              <div className="mx-auto flex justify-content-center mb-3">
                                <img
                                  className="w-6 h-6 cursor-pointer mx-1 mt-0.5"
                                  src={IconEdit}
                                  title="Modificar proyecto"
                                  alt=""
                                  onClick={() => editProject(data.id)}
                                />
                                <img
                                  className="w-7 h-7 cursor-pointer mx-1"
                                  src={IconDelete}
                                  title="Eliminar proyecto"
                                  alt=""
                                  onClick={() => deleteProject(data.id)}
                                />
                              </div>
                              <p
                                className="card-text text-"
                                style={{ color: "#9A9A9A" }}
                              >
                                Nombre cliente
                              </p>
                              <p className="card-text text-lg fw-semibold">
                                {dataClient.firstName}{" "}
                                {dataClient.firstLastName}
                              </p>
                              {/* <p
                                className="card-text text-base mt-2"
                                style={{ color: "#9A9A9A" }}
                              >
                                ID
                              </p>
                              <p className="card-text text-lg fw-semibold">
                                {data.id}
                              </p> */}
                              <p
                                className="card-text text-base mt-2"
                                style={{ color: "#9A9A9A" }}
                              >
                                Nombre del proyecto
                              </p>
                              <p className="card-text text-lg fw-semibold">
                                {data.title}
                              </p>
                              <p
                                className="card-text text-base mt-2"
                                style={{ color: "#9A9A9A" }}
                              >
                                Breve descripción
                              </p>
                              <p className="card-text text-lg text-justify fw-semibold overflow-hidden">
                                {data.description.substring(0, 150)}...
                              </p>
                              <p
                                className="card-text text-base mt-2"
                                style={{ color: "#9A9A9A" }}
                              >
                                Alcances del servicio a realizar
                              </p>
                              <p className="card-text text-lg text-justify fw-semibold overflow-hidden">
                                {data.scope.substring(0, 150)}...
                              </p>
                              {savesQuotes[data.id] && (
                                <>
                                  <hr className="my-2.5" />
                                  {savesQuotes[`${data.id}`].map(
                                    (quote, index) => {
                                      return (
                                        <p
                                          className="card-text text-lg fw-semibold"
                                          key={index}
                                        >
                                          Tiene una cotización guardada sin
                                          enviar | #{index}.
                                          <br />
                                          <button
                                            type="button"
                                            onClick={() => {
                                              _getProjectId(data.id);
                                              setConceptsProps(quote.fees);
                                              setIdQuoteLocalStorage(index);
                                              setShowModalCot(true);
                                              // previewQuote(quotes);
                                            }}
                                            className="btn rounded-pill ColorButtonMainInv my-2"
                                          >
                                            Click aquí para abrir
                                          </button>
                                        </p>
                                      );
                                    }
                                  )}
                                </>
                              )}
                              <hr className="my-2.5" />
                              <p
                                className="card-text text-base my-2"
                                style={{ color: "#9A9A9A" }}
                              >
                                Cotizaciones:
                              </p>
                              {Quotes[data.id].map((quotes) => {
                                return (
                                  <div key={quotes.id} className="my-2">
                                    <p
                                      className="card-text text-lg fw-semibold"
                                      style={{ color: "#9A9A9A" }}
                                    >
                                      {formatDate(quotes.createDate)}
                                    </p>
                                    <div className="md:block flex gap-3">
                                      <button
                                        type="button"
                                        className="btn rounded-pill ColorButtonMainGreen mt-2"
                                        onClick={() => previewQuote(quotes)}
                                      >
                                        <div className="flex content-center items-center">
                                          <img
                                            src={LogoView}
                                            alt=""
                                            className="w-4 mr-2 TouchGreen"
                                          />
                                          Previsualizar documento
                                        </div>
                                      </button>

                                      {/* <PDFDownloadLink
                                        className="btn rounded-pill ColorButtonMainGreen mt-2 mx-auto lg:ml-0"
                                        document={
                                          <PDF
                                            dataClient={dataClient}
                                            dataProject={quotes}
                                            dataProducts={products}
                                            dataOwner={{
                                              ...dataOwner[data.id],
                                            }}
                                            termsAndConditions={
                                              termsAndConditions
                                            }
                                          />
                                        }
                                        fileName={`PDF Cotización ${data.title}.pdf`}
                                      >
                                        <div className="flex content-center items-center">
                                          <img
                                            src={LogoDownload}
                                            alt=""
                                            className="w-4 mr-2 TouchGreen"
                                          />
                                          Descargar PDF
                                        </div>
                                      </PDFDownloadLink> */}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                setShowModalCot(true);
                                _getProjectId(data.id);
                              }}
                              className="btn rounded-pill ColorButtonMainInv my-1 ml-12 lg:ml-0 mx-4"
                            >
                              Crear nuevo cotización
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {showModal && (
            <div
              className="App h-screen ml-[90px] flex flex-col items-center justify-center bg-purple-200 vw-100 vh-100"
              style={{
                zIndex: "10",
                background: "rgba(0, 162, 151, .55)",
                position: "fixed",
                top: "0",
                right: "0",
                bottom: "0",
                left: "0",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              <CreateProjects
                clientId={clientId}
                contactId={contactId}
                projectId={projectId}
                reference={reference}
                review={review}
                title={title}
                m2={m2}
                location={location}
                description={description}
                scope={scope}
                dataClient={dataClient}
                _addClientProject={_addClientProject}
                setShowModal={setShowModal}
                setClientId={setClientId}
                setReference={setReference}
                setReview={setReview}
                setTitle={setTitle}
                setM2={setM2}
                setLocation={setLocation}
                setDescription={setDescription}
                setScope={setScope}
              />
            </div>
          )}

          {showModalCot && (
            <div
              className="App h-screen ml-[90px] flex flex-col items-center justify-center bg-purple-200"
              style={{
                zIndex: "10",
                background: "rgba(0, 162, 151, .55)",
                position: "fixed",
                top: "0",
                right: "0",
                bottom: "0",
                left: "0",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              <CreateQuote
                clientId={clientId}
                contactId={contactId}
                projectId={projectId}
                reference={reference}
                review={review}
                dataProject={dataProject}
                dataClient={dataClient}
                setShowModalCot={setShowModalCot}
                Context={Context}
                idQuotes={idQuotes}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                conceptsProps={conceptsProps}
                setConceptsProps={setConceptsProps}
                idQuoteLocalStorage={idQuoteLocalStorage}
                setIdQuoteLocalStorage={setIdQuoteLocalStorage}
              />
            </div>
          )}
        </>
      )}
      {activeModal && (
        <div
          className="App h-screen ml-[90px] flex flex-col items-center justify-center bg-purple-200 vw-100 vh-100"
          style={{
            zIndex: "10",
            background: "rgba(0, 162, 151, .55)",
            position: "fixed",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <Modal
            Context={Context}
            clientId={dataProject.idClient}
            dataClient={dataClient}
            dataProject={dataProject}
            projectId={dataProject.projectId}
            setShowModalCot={setActiveModal}
            isUpdate={false}
            setIsUpdate={() => {}}
          />
        </div>
      )}
    </>
  );
}
